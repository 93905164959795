import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';

const PostCard = ({ data, className, ...rest }) => (
  <li className={`blog-post ${className}`} {...rest}>
    <Link
      className="blog-post__image"
      to={`/blog/${data.post.frontmatter.slug}/`}
    >
      <GatsbyImage
        className="h-full"
        fluid={data.post.frontmatter.coverImage.childImageSharp.fluid}
        alt={data.post.frontmatter.title}
      />
    </Link>
    <Link
      className="blog-post__title"
      to={`/blog/${data.post.frontmatter.slug}/`}
    >
      <h2>{data.post.frontmatter.title}</h2>
    </Link>
    <p>{data.post.frontmatter.meta?.description || data.post.excerpt}</p>
  </li>
);

PostCard.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.shape({
      excerpt: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string,
        coverImage: PropTypes.any,
        meta: PropTypes.object,
      }),
    }),
  }),
  className: PropTypes.string,
};

export default PostCard;

export const query = graphql`
  fragment PostCard on MarkdownRemarkEdge {
    post: node {
      excerpt
      frontmatter {
        title
        slug
        coverImage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        meta {
          description
        }
      }
    }
  }
`;
