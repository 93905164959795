import React from 'react';
import PropTypes from 'prop-types';

const PostList = ({ children }) => (
  <ul className="list-none mb-8 lg:mb-14 grid gap-y-12 md:grid-cols-2 md:gap-x-8 lg:grid-cols-3 lg:gap-x-4 xl:gap-x-16">
    {children}
  </ul>
);

PostList.propTypes = {
  children: PropTypes.node,
};

export default PostList;
