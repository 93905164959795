import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../layout/layout';
import SEO from '../meta/seo';
import PostCard from './post/card';
import PostList from './post/list';

const Blog = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/posts/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
          }
          ...PostCard
        }
      }
    }
  `);
  return (
    <Layout campaign="blog">
      <SEO
        title="Video Editing Tips and Social Media Trends"
        description="Discover Splice app Blog. You can find awesome tips on how to create high-quality videos, filming on a budget, and discovering the latest social media trends."
      />

      <div className="container space-around">
        <div className="mb-8 md:mb-12 xl:mb-16">
          <h1 className="text-main-title mb-4 md:mb-6">Blog.</h1>
          <p className="text-short-lead max-w-secondary-content">
            At Splice, we value words (almost) as much as visuals! Here&apos;s
            where you can find awesome tips on how to create high-quality video
            content, filming on a budget, and discovering the latest social
            media trends.
          </p>
        </div>

        <PostList>
          {allMarkdownRemark.edges.map((edge, index) => (
            <PostCard
              data={edge}
              key={edge.node.id}
              className={index == 0 ? 'blog-post--highlight' : ''}
            />
          ))}
        </PostList>
      </div>
    </Layout>
  );
};

export default Blog;
